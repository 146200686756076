import React from 'react';
// Icon
class IconMountain extends React.Component {
  render() {
    return (
      <svg id="IconMountain" className={ this.props.styleName } version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"viewBox="0 0 200 200" enableBackground="new 0 0 200 200">
        <g><path d="m163.83,96.77l-3.11,18.95-33.64-72.32v-16.3c.95,1.36,1.97,2.14,3.1,2.37.24.05.47.07.71.07,1.85,0,3.62-1.4,5.33-2.76,1.84-1.46,3.58-2.83,5.22-2.49,1.03.21,1.97,1.07,2.9,2.64l.98,1.66v-15.01l-.09-.15c-1.07-1.74-2.22-2.69-3.51-2.91-2.13-.36-4.18,1.39-6.15,3.08-1.79,1.54-3.65,3.13-5.32,2.85-.98-.17-1.9-.96-2.79-2.41l-.38-.62v-1.02c0-.97-.72-1.76-1.6-1.76s-1.6.79-1.6,1.76v27.2l-35.94,69.53-35.41-43.72L1.02,193.54h198.32l-35.51-96.77ZM127.56,16.03c.79.9,1.63,1.41,2.54,1.57,2.14.37,4.18-1.39,6.15-3.08,1.79-1.54,3.64-3.13,5.32-2.85.95.16,1.83.9,2.7,2.26v10.94c-.81-.98-1.68-1.55-2.62-1.74-2.1-.43-4.11,1.16-6.05,2.69-1.84,1.46-3.59,2.83-5.23,2.49-.99-.2-1.92-1.02-2.82-2.5v-9.79ZM47.59,99.14l2.4-2.15,4.35,6.53,4.84-12.49,7.52,4.56,22.74,28.07,19.23-37.2,8.3-7.28,6.16,7.85,6.16-11.73,8.9,10.88,26.25,56.43.2-1.24,3.75-4.27,4.5,7.88-.18-.87,15.2,41.41H12.86l34.73-86.4Z"/></g>
      </svg>
    );
  }
}

export default IconMountain;