import React from 'react';
// Icon
class IconKnife extends React.Component {
  render() {
    return (
      <svg id="IconKnife" className={ this.props.styleName } version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" enableBackground="new 0 0 100 100">
      <path d="M92,1.1L37.1,59.2l-30.9,28c0,0,0.4,7.4,9.8,11.4c1.2,0.6,3.4-2.8,3.4-2.8l-1.6-1.5c4.9-4.3,18.6-11.1,28.2-25.9l5.5,6.7
        c0,0,24.2-16.7,35.6-40.6C98.4,10.6,92,1.1,92,1.1z M19.1,86.9c-0.8,0.8-2.1,0.8-2.9,0c-0.8-0.8-0.8-2.1,0-2.8
        c0.8-0.8,2.1-0.8,2.9,0C19.9,84.8,19.9,86.2,19.1,86.9z M27.5,80.4c-0.8,0.8-2.1,0.8-2.9,0c-0.8-0.8-0.8-2.1,0-2.8
        c0.8-0.8,2.1-0.8,2.9,0C28.3,78.3,28.3,79.7,27.5,80.4z M36,74c-0.8,0.8-2.1,0.8-2.8,0c-0.8-0.8-0.8-2.1,0-2.8
        c0.8-0.8,2.1-0.8,2.8,0C36.8,71.9,36.8,73.2,36,74z"/>
      </svg>
    );
  }
}

export default IconKnife;