import React from 'react'

import CaseStudies from '../../Components/CaseStudy/CaseStudies'
import CaseStudyHeader from '../../Components/CaseStudy/CaseStudyHeader'
import CaseStudySection from '../../Components/CaseStudy/CaseStudySection'

class DaveBustersUX extends React.Component {
  render() {
    const content =[
      { title: "Situation",
        text:<div className="col-sm-10 offset-sm-1">
              <h5>Goal</h5>
              <p>Dave & Buster’s wanted a digital experience that could capture the fun and excitement of their brand while enhancing user engagement and driving more in-person visits. They needed a user-centered overhaul of their website and app to simplify navigation, improve accessibility of key features, and better reflect their brand’s energetic vibe.</p>
              <h5>Problem</h5>
              <p>The existing interface had several usability issues. Users reported difficulty in accessing rewards, booking events, and finding promotions, which created a frustrating experience that ultimately impacted user retention and satisfaction.</p>
              <h5>Task</h5>
              <p>My primary task was to build a digital experience rooted in empathy for the end users, focusing on simplicity, ease of access, and enjoyment. To do this effectively, I prioritized a human-centered approach, aiming to make each step of the journey intuitive and aligned with user expectations while still resonating with Dave & Buster’s brand identity. This required not only addressing usability issues but also developing a strong UI design foundation and a flexible design system to maintain consistency as the product evolved.</p>
              <h5>My Role</h5>
              <p><span>Sr. Director of UX</span>As the UX lead, I was brought on to reimagine Dave & Buster’s digital user journey from a human-centered design perspective. This involved deeply understanding the users’ needs, identifying pain points, and guiding cross-functional teams to create a solution that would make users feel at ease and engaged.</p>
            </div>},
      { title: "Discovery",
        text:<div>
          <div className='row align-items-center'>
              <div className="col-sm-10 offset-sm-1">
                <h5>Empathy and Research:</h5>
                <p>The team began by embedding themselves in the user’s perspective, conducting interviews and usability studies to understand their motivations, frustrations, and goals. Many users felt frustrated by the difficulty of navigating to essential features like rewards and bookings. Through this empathy-driven research phase, we gained valuable insights into how real users experienced the app and website, which formed the foundation for our design approach.
                </p>
                <p className='text-center'><img alt="Dave and Busters discovery" src={'../../images/caseStudies/DB-discovery.png'}></img></p>
              </div>
              </div>
              <div className='row align-items-center'>
              <div className="col-sm-10 offset-sm-1">
              <h5>Users & Mapping: Defining the Problem through User Insights:</h5>
                <p>The insights gathered helped clarify our design focus. I worked with my team to map out user journeys and identify key friction points. This defined our problem statement and design goals, ensuring we were aligned on creating a solution that was truly grounded in user needs. We prioritized a clear, simplified navigation and a more accessible rewards feature, organizing information in a way that would naturally align with how users expected to interact with the app.
                </p>
              </div>
              <div className="col-sm-10 offset-sm-1">
                <p className='text-center'><img alt="User journey maps with additional screen flow images from dave and busters." src={'../../images/caseStudies/DB-flows.png'}></img></p>
              </div>
            </div>
            </div>
      },
      { title: "Prototyping & User Testing",
        text:<div>
                <div className='row align-items-center'>
                  <div className="col-sm-10 offset-sm-1">
                    <h5>Ideate and Iterate:</h5>
                    <p>Using the information gathered, I led my team in creating low-fidelity wireframes that focused on improving usability. We followed human-centered design principles by testing each iteration with real users, gathering feedback, and continuously refining the design. For instance, when users expressed a desire for immediate visibility of their rewards balance, we redesigned the homepage to make this information prominent. We also improved the placement of the booking feature, making it easier to find based on user expectations</p>
                  </div>
                </div>
                <div className='row align-items-start'>
                  <div className="col-sm-10 offset-sm-1">
                    <p className='text-center'><img alt="Wireframes depicting dave and busters book a party flow." src={'../../images/caseStudies/DB-proto.png'}></img></p>
                  </div>
                  <div className="col-sm-10 offset-sm-1">
                    <p>Figma wireframes were created for user testing and prototypes. We leveraged remote testing via UserZoomGo and heatmapping via HotJar. Two rounds of user testing provided some hypothesis validation, and visual direction.</p>
                  </div>
                </div>
                <div className='row align-items-center'>
                  <div className="col-sm-10 offset-sm-1">
                    <h5>UI Design:</h5>
                    <p>To reflect the brand’s lively personality, I developed a visually engaging UI with a color palette and typography that matched Dave & Buster’s energy. We introduced playful, yet unobtrusive, interactive elements and iconography that made the experience feel inviting without detracting from functionality.</p>
                  </div>
                  <div className="col-sm-10 offset-sm-1">
                    <h5>Design System:</h5>
                    <p>To ensure consistency across all digital touchpoints, I created a robust design system. This included a library of reusable components, standardized color schemes, typography scales, and spacing guidelines. The design system not only provided a cohesive look but also ensured scalability, allowing future updates to maintain the same visual integrity and alignment with the brand’s identity. Each component was tested for accessibility, ensuring users of all abilities could engage seamlessly with the digital experience.</p>
                  </div>
                </div>
                <div className='row align-items-center'>
                  <div className="col-sm-10 offset-sm-1">
                    <p className='text-center'><img alt="Temp Title" src={'../../images/caseStudies/DB-designsystem.png'}></img></p>
                  </div>
                  <div className="col-sm-10 offset-sm-1">
                    <h5>Prototype and Test:</h5>
                    <p>High-fidelity prototypes were created and tested to validate both the UX and UI elements, ensuring that the visual appeal did not compromise functionality. I conducted A/B tests to confirm design choices, adjusting interactive elements, colors, and layouts based on user feedback. By maintaining continuous feedback loops, we ensured that the design was as usable as it was visually aligned with the brand’s personality.</p>
                  </div>
                </div>
              </div>
      },
      { title: "Results",
        text:
          <div>
            <div className="col-sm-10 offset-sm-1">
              <h5>Better Usability:</h5>
              <p>The redesigned digital experience significantly boosted Dave & Buster’s user engagement and brand loyalty by addressing core user needs and aligning with the brand’s energetic identity. The cohesive interface, coupled with the design system, provided a user-friendly and visually appealing platform that facilitated seamless navigation, increased retention, and reinforced the company’s digital presence.</p>
              <ul>
                <li><b>Increased User Engagement:</b> The intuitive layout and prominent rewards display led to a 35% increase in user engagement with the app, as users found it easier to track and redeem rewards. Enhanced access to the rewards program motivated users to return and interact more frequently with the platform, driving higher engagement levels across the site and app.</li>
                <li><b>Improved Event Booking Conversion Rates:</b> By repositioning and simplifying the booking feature based on user testing insights, conversion rates for event bookings rose by 20%. Users expressed satisfaction with the ease of booking directly through the app, contributing to increased foot traffic at Dave & Buster’s locations.</li>
                <li><b>Enhanced Brand Loyalty and Retention:</b> The implementation of a cohesive design system not only ensured visual consistency but also helped Dave & Buster’s deliver a dependable, scalable user experience across digital platforms. This stability contributed to a 25% improvement in retention rates for rewards program users, as users now had a straightforward, reliable way to manage rewards and engage with the brand online.</li>
              </ul>
              <p>In post-launch surveys, users consistently rated the new digital experience as simpler, more engaging, and more aligned with Dave & Buster’s brand. The successful application of human-centered design principles ultimately transformed the digital journey, proving that empathy and user-focused iteration are powerful tools for crafting an experience that users love.</p>
            </div>
          </div>
      }
    ]
    return (
      <section>
        <article className="container">
          <div className="row">
            <CaseStudyHeader
              CsTitle = "Project: Dave & Busters UX"
              CsSubtitle = "Improved UX, visual design, and lead generation."
              CsHero = {'../../images/caseStudies/DB-Hero.png'}
            />
            <CaseStudySection content={content} />
            <CaseStudies />
          </div>
        </article>
      </section>
    )
  }
}

export default DaveBustersUX



