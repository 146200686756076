import React from 'react'

import CaseStudies from '../../Components/CaseStudy/CaseStudies'
import CaseStudyHeader from '../../Components/CaseStudy/CaseStudyHeader'
import CaseStudySection from '../../Components/CaseStudy/CaseStudySection'

class CocaColaAnd extends React.Component {
  render() {
    const content =[
      { title: "Objective",
        text:<div className="col-sm-10 offset-sm-1">
              <h5>Goal</h5>
              <p>Coca-Cola wanted to enhance its digital experience to foster deeper engagement and brand loyalty. We were challenged to create an exceptional consumer experience that isn't just your everyday recipe "listing" experience. The aim was to create an online experience that felt as memorable and inviting as their in-person brand, allowing users to access promotions, rewards, and information seamlessly.
              </p>
              <h5>Problem</h5>
              <p>On a very tight timeline, this project needed to be designed, tested and ship within a three month window, so it could be used at the SOBE Food & Wine Festival. The site was designed for a mobile device, knowing that the users would be carrying their phones around the festival and to leverage kiosks and QR code stations.</p>
              <h5>Task</h5>
              <p>Our task was to create a user-friendly, visually appealing digital experience that would align with Coca-Cola’s brand identity. The goal was to make promotions, rewards, and brand content easy to find and interact with while ensuring a smooth, engaging user journey that encouraged repeat visits and brand loyalty.
              </p>
              <h5>My Role</h5>
              <p><span>Senior Director of UX</span>As the UX lead, my role was to apply human-centered design principles to redesign Coca-Cola’s digital interface. This involved understanding the user’s mindset and behaviors, iterating on design based on feedback, and leading the creation of a visually cohesive and engaging experience that would make users feel valued and connected to the brand.</p>
            </div>},
      { title: "Discovery",
        text:<div className="col-sm-10 offset-sm-1">
                <h5>Competitive Analysis – Who is doing something similar?</h5>
                <p>We analyzed trends, and reviewed 20+ recipe sites and mixing sites to identify expectations and current user journeys.</p>
                <h5>User Journey – What's the expectation?</h5>
                <p>There are many recipe sites available. How could a unique experience be created that leverages the Coca-Cola brand equity along with a fun way to find drinks.</p>
                <h5>Empathy and User Research:</h5>
                <p>In conjunction with our strategy team, we started by diving deep into user research, conducting interviews and usability tests to uncover how users perceived Coca-Cola’s online experience and what they expected from it. The research revealed that users wanted a streamlined experience with quick access to promotions, a clear rewards section, and interactive brand moments that captured Coca-Cola’s vibrant personality. These insights guided every phase of the design process, anchoring our approach in real user needs.</p>
                <div className="col-sm-10 offset-sm-1">
                  <p className='text-center'><img alt="Coca-ColaAnd Discovery and Competitve analysis" src={'../../images/caseStudies/cokeAnd-Discovery.png'}></img></p>
                </div>
                <h5>Defining the Problem through User Insights:</h5>
                <p>With a clear understanding of user frustrations and desires, I collaborated with my team to map user journeys and identify primary friction points. We saw that users were often unable to find relevant promotions and rewards easily, and they felt disconnected from the brand online. We refined our problem statement and design goals based on these insights, committing to an intuitive navigation, prominently displayed promotions, and a dynamic rewards experience that would meet and exceed user expectations.</p>
                <div className="col-sm-10 offset-sm-1">
                  <p className='text-center'><img alt="Coca-ColaAnd Discovery and User Flows" src={'../../images/caseStudies/cokeAndSitemapConsumerFlow.jpg'}></img></p>
                </div>
              </div>
      },
      { title: "Ideation & Design",
        text:<div>
                <div className='row align-items-center'>
                  <div className="col-sm-10 offset-sm-1">
                    <h5>Ideation & Wireframes:</h5>
                    <p>Our Creative Director and myself sat down between client meetings to quickly hash out an approach that would be user friendly and beautiful. Based on our findings, we created low-fidelity wireframes focusing on a cleaner, visually balanced interface that addressed user needs. Using human-centered design principles, we tested each iteration with real users, making adjustments to ensure we stayed true to their expectations. For instance, users wanted immediate access to rewards, so we redesigned the homepage to make rewards a key feature. Each design round incorporated user feedback, refining the layout, icons, and navigation for simplicity and appeal.
                    </p>
                    <h5>UI Design:</h5>
                    <p>The visual aspect was critical for Coca-Cola, so working with our Creative Director, we focused on creating a vibrant, cohesive UI that mirrored the brand’s identity. I chose a color palette and typography that aligned with Coca-Cola’s aesthetic, adding subtle animations to make the experience feel lively and engaging.</p>
                  </div>
                </div>
                <div className='row align-items-start'>
                  <div className="col-sm-10 offset-sm-1">
                    <p className='text-center'><img alt="Coca-Cola And Wireframes" src={'../../images/caseStudies/cokeAnd-Wires.png'}></img></p>
                  </div>
                  <div className="col-sm-10 offset-sm-1">
                    <h5>Prototype and Test:</h5>
                    <p>From wires, we build a more robust prototype in Adobe XD to obtain client buy-in and to sell the concept. Through iterative testing, we fine-tuned elements like button placement, color contrast, and content layout to ensure every component enhanced the user experience. The consistent feedback loop allowed us to make data-informed decisions that aligned with Coca-Cola’s brand and user needs.</p>
                  </div>
                  <div className="col-sm-10 offset-sm-1">
                    <p className='text-center'><img alt="Coca-Cola And Prototype" src={'../../images/caseStudies/cokeAnd-Prototypes.png'}></img></p>
                  </div>
                </div>
              </div>
      },
      { title: "Closing the Loop",
        text: <div className='row align-items-center'>
                <div className="col-sm-10 offset-sm-1">
                  <h5>Client reaction:</h5>
                  <p>The redesigned Coca-Cola digital experience achieved tangible improvements in user engagement, retention, and brand loyalty. By rooting the design in human-centered principles, we crafted a platform that users enjoyed and trusted, with increased usability and a clear connection to the Coca-Cola brand.
                  </p>
                  <ul>
                    <li><b>Boosted User Engagement:</b> The new interface led to an increase in user interactions, with more users actively engaging with rewards and promotions. The accessible, inviting layout encouraged users to explore the platform more fully, resulting in a higher level of engagement with each session.</li>
                    <li><b>Higher Conversion Rates for Promotions:</b> By prominently positioning promotions and ensuring they were easy to find and interact with, we achieved an increase in promotion conversions. Users felt motivated to participate in offers, translating digital engagement into in-person interactions with the brand.</li>
                    <li><b>Enhanced Brand Loyalty and Retention:</b> The cohesive design system and accessible interface led to an improvement in user retention. The seamless navigation and visually appealing design made users more likely to return, fostering a sense of loyalty and connection to Coca-Cola.</li>
                  </ul>
                  <p>User feedback confirmed that the redesigned experience was not only intuitive but also enjoyable, with users praising the simplicity and visual appeal. By applying human-centered design, we created a digital journey that allowed users to connect with Coca-Cola in meaningful ways, solidifying the brand’s presence in their digital lives.</p>
                  <h5>Was this successful?</h5>
                  <p>The projects MVP "<a href="https://mixology.coca-cola.com" target="_blank" rel="noreferrer">mixology.coca-cola.com</a> was released with 2 days to spare before the festival!</p>
                  <p><a href="https://www.linkedin.com/posts/rachel-cantlay_after-5-months-of-learning-collaborating-activity-7034375239757172736-F_f7" target="_blank" rel="noreferrer">The client</a> loved it! It was also featured in <a href="https://www.bhg.com/coca-cola-mixology-site-7255614" target="_blank" rel="noreferrer">Better Homes & Gardens</a> with a specific call out regarding the search functionality.</p>
                  <p>Post-festival survey feedback will help determine if the project was effective at grabbing attention and engaging users.</p>
                  <h5>What's next?</h5>
                  <p>Intenal facing site that includes recipe cards that can be created for sell-in opportunities.</p>
                  <p>Refinements to the navigation to allow stronger filtering of content, and a more robust fuzzy search.</p>
                  <p>Lead generation optimization and opportunities assessments.</p>
                  <p>The ability to add influencer generated content.</p>
                </div>
              </div>
      }
    ]
    return (
      <section>
        <article className="container">
          <div className="row">
            <CaseStudyHeader
              CsTitle = "Project: Coca-Cola&"
              CsSubtitle = "Mixology site for Coca-Cola."
              CsHero = {'../../images/caseStudies/CokeAnd-Hero-Desktop.png'}
            />
            <CaseStudySection content={content} />
            <CaseStudies />
          </div>
        </article>
      </section>
    )
  }
}

export default CocaColaAnd