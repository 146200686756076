import React from 'react';
// Icon
class IconBulb extends React.Component {
  render() {
    return (
      <svg id="IconBulb" className={ this.props.styleName } version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"viewBox="0 0 200 200" enableBackground="new 0 0 200 200">
        <g display="none">
          <path display="inline" d="M184,2.2L74.2,118.4l-61.8,55.9c0,0,0.8,14.8,19.5,22.9c2.5,1.1,6.8-5.6,6.8-5.6l-3.2-3
            c9.9-8.6,37.3-22.2,56.4-51.8l10.9,13.4c0,0,48.4-33.3,71.2-81.2C196.8,21.2,184,2.2,184,2.2z M38.2,173.9c-1.6,1.6-4.2,1.6-5.7,0
            c-1.6-1.6-1.6-4.2,0-5.7c1.6-1.6,4.2-1.6,5.7,0C39.8,169.7,39.8,172.3,38.2,173.9z M55,160.9c-1.6,1.6-4.2,1.6-5.7,0
            c-1.6-1.6-1.6-4.2,0-5.7c1.6-1.6,4.2-1.6,5.7,0C56.6,156.7,56.6,159.3,55,160.9z M71.9,148c-1.6,1.6-4.2,1.6-5.7,0
            c-1.6-1.6-1.6-4.2,0-5.7c1.6-1.6,4.2-1.6,5.7,0C73.5,143.8,73.5,146.4,71.9,148z"/>
        </g>
        <g id="Layer_2">
          <path d="M117,190c0,0.6-0.8,1-1.3,1H84.3c-0.5,0-1.3-0.4-1.3-1v-3.8c0-0.5,0.8-1.2,1.3-1.2h31.4c0.5,0,1.3,0.7,1.3,1.2V190z
             M117,178.2c0-0.5,0.1-1.2-0.5-1.2H83.5c-0.5,0-0.5,0.7-0.5,1.2v3.8c0,0.6-0.1,1,0.5,1h33.1c0.5,0,0.5-0.4,0.5-1V178.2z M119,170
            c0-0.6-0.4-1-1-1H83c-0.6,0-1,0.4-1,1v4c0,0.6,0.4,1,1,1h35c0.6,0,1-0.4,1-1V170z M149.3,91.2c0.8,33.8-31.3,59.6-31.3,59.6V165
            h-15.7l8.9-52.5l-5.7-1.1l-6.1,35.7l-13.1-38.8l14.7,8.4l-6.4-16.2l18.4,8.2V94h-5v6L84.7,89.8l5.4,14L76,96l23.2,69h-0.4H81v-14.2
            c0,0-31.6-25.9-30.8-59.6C51,58.5,72.7,43,96.5,42h6.9C127.3,43,148.5,58.5,149.3,91.2z M113.4,61.4c-19.9-11.6-38,10.7-38,10.7
            c6.7-2.6,24.4-6.6,34.2-1.5c18.8,9.6,16.5,32.4,14.3,42.4c-2.6,7.3-1.3,5.7,0,0c0.3-0.8,0.6-1.7,1-2.8
            C134.9,83.5,124.8,68.1,113.4,61.4z M102,18c0-1.6-0.7-3-2-3s-2,1.4-2,3v9c0,1.6,0.7,3,2,3s2-1.4,2-3V18z M133,25.2
            c0.6-1.5,0.1-3.2-1.2-3.7c-1.3-0.5-2.8,0.3-3.4,1.9l-3.5,8.7c-0.6,1.5-0.1,3.2,1.2,3.7c1.3,0.5,2.8-0.3,3.4-1.9L133,25.2z
             M165.2,48.1c1.5-0.7,1.9-2.1,1-3.2s-3-1.4-4.5-0.8l-9.3,4.1c-1.5,0.7-1.9,2.1-1,3.2s3,1.4,4.5,0.8L165.2,48.1z M173.7,82.6
            c1.6-0.3,2.7-1.7,2.5-3.1c-0.3-1.4-1.8-2.3-3.4-2l-9.3,1.8c-1.6,0.3-2.7,1.7-2.5,3.1c0.3,1.4,1.8,2.3,3.4,2L173.7,82.6z
             M169.4,114.7l-8.7-2.8c-1.6-0.5-3.2,0.1-3.7,1.3c-0.5,1.2,0.4,2.7,2,3.2l8.7,2.8c1.6,0.5,3.2-0.1,3.7-1.3
            C171.8,116.7,170.9,115.2,169.4,114.7z M159.3,138.1l-7.3-3.9c-1.5-0.8-3.4-0.6-4.4,0.4c-1,1-0.5,2.5,0.9,3.2l7.3,3.9
            c1.5,0.8,3.4,0.6,4.4-0.4C161.2,140.4,160.8,138.9,159.3,138.1z M70.5,33.9c0.6,1.5,2.2,2.4,3.4,1.9c1.3-0.5,1.8-2.2,1.2-3.7
            l-3.5-8.7c-0.6-1.5-2.2-2.4-3.4-1.9c-1.3,0.5-1.8,2.2-1.2,3.7L70.5,33.9z M44.2,52.1c1.5,0.7,3.6,0.3,4.5-0.8s0.6-2.5-1-3.2
            l-9.3-4.1c-1.5-0.7-3.6-0.3-4.5,0.8s-0.6,2.5,1,3.2L44.2,52.1z M35.6,84.4c1.6,0.3,3.2-0.6,3.4-2c0.3-1.4-0.8-2.8-2.5-3.1l-9.3-1.8
            c-1.6-0.3-3.2,0.6-3.4,2c-0.3,1.4,0.8,2.8,2.5,3.1L35.6,84.4z M28.6,117.9c0.5,1.2,2.1,1.8,3.7,1.3l8.7-2.8c1.6-0.5,2.5-1.9,2-3.2
            c-0.5-1.2-2.1-1.8-3.7-1.3l-8.7,2.8C29.1,115.2,28.2,116.7,28.6,117.9z M39.7,141.4c1,1,2.9,1.2,4.4,0.4l7.3-3.9
            c1.5-0.8,1.9-2.2,0.9-3.2c-1-1-2.9-1.2-4.4-0.4l-7.3,3.9C39.2,138.9,38.8,140.4,39.7,141.4z"/>
        </g>
      </svg>
    );
  }
}

export default IconBulb;