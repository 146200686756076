import React from 'react';
// Icon
class IconScrollTop extends React.Component {  
  render() {
    return (
      <svg id="IconScrollTop" width="98px" height="98px" viewBox="0 0 98 98" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <title>IconScrollTop</title>
          <g stroke="none" strokeWidth="1" fillRule="evenodd">
              <g transform="translate(-1.000000, 0.000000)" >
                  <path d="M4,0 L96,0 C97.6568542,-3.04359188e-16 99,1.34314575 99,3 L99,95 C99,96.6568542 97.6568542,98 96,98 L4,98 C2.34314575,98 1,96.6568542 1,95 L1,3 L1,3 C1,1.34314575 2.34314575,3.04359188e-16 4,0 L4,0 Z M18.5,59.9375 C18.5,60.2291681 18.5520828,60.4999988 18.65625,60.75 C18.7604172,61.0000012 18.9166656,61.2291656 19.125,61.4375 C19.5416687,61.8541687 20.0416638,62.0625 20.625,62.0625 C21.2083362,62.0625 21.7083313,61.8541687 22.125,61.4375 L50.375,33.25 L78.1875,61.0625 C78.6041687,61.4791687 79.1041637,61.6875 79.6875,61.6875 C80.2708363,61.6875 80.7708313,61.4791687 81.1875,61.0625 C81.6041687,60.6458313 81.8125,60.1458363 81.8125,59.5625 C81.8125,58.9791637 81.6041687,58.4791687 81.1875,58.0625 L51.875,28.6875 C51.4583313,28.3124981 50.9583363,28.125 50.375,28.125 C49.7916637,28.125 49.2916687,28.3124981 48.875,28.6875 L19.125,58.4375 C18.9166656,58.6458344 18.7604172,58.8854153 18.65625,59.15625 C18.5520828,59.4270847 18.5,59.6874988 18.5,59.9375 Z" id="IconScrollTop-shape"></path>
              </g>
          </g>
      </svg>
    );
  }
}

export default IconScrollTop;