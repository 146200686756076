import React from 'react'

import Header from '../Components/Header'
import CaseStudies from '../Components/CaseStudy/CaseStudies'

class Home extends React.Component {
  render() {
    return(
      <div>
        <Header
          title="Michael McMillan,"
          jobTitle="UX Leader"
          subtitle="People-focused UX leader and strategist, producing elegant designs to meet business objectives, and create thoughtful experiences and solutions."s
        />
        <CaseStudies />
      </div>
    )
  }
}

export default Home