import React from 'react'

import CaseStudies from '../../Components/CaseStudy/CaseStudies'
import CaseStudyHeader from '../../Components/CaseStudy/CaseStudyHeader'
import CaseStudySection from '../../Components/CaseStudy/CaseStudySection'

class CallCenterDashboard extends React.Component {
  render() {
    const content =[
      { title: "Objective",
        text:<div className="col-sm-10 offset-sm-1">
              <h5>Goal</h5>
              <p>eLocal aimed to develop a new internal call center dashboard that could improve operational efficiency and help agents make quick, informed decisions by creating an interface that empowered agents with real-time data, actionable insights, and a clear view of performance metrics.
              </p>
              <h5>Problem</h5>
              <p>The existing process for accessing data and insights was cumbersome, with agents needing to navigate multiple systems to find essential information. This inefficiency slowed response times, impacting both agent productivity and customer satisfaction. The challenge was to design a unified, user-friendly dashboard that streamlined these tasks without overwhelming agents.
              </p>
              <h5>Task</h5>
              <p>My primary task was to create a human-centered design solution that streamlined agents’ workflows, allowing them to access key metrics and data with minimal clicks. The objective was to develop an efficient, visually clear, and intuitive dashboard that agents could rely on to make faster, data-driven decisions.
              </p>
              <h5>My Role</h5>
              <p><span>UX Director & Creative Director</span>As the UX lead for this new internal product, my role was to lead the design and development of an intuitive dashboard from scratch, ensuring it aligned with the real needs of agents. I was responsible for understanding the daily workflows of the agents, identifying the information they needed most, and designing an interface that provided clear, accessible data at a glance.
              </p>
            </div>},
      { title: "Discovery",
        text:<div className="col-sm-10 offset-sm-1">
                <h5>Competitive Analysis – Who is doing something similar?</h5>
                <p>At the time, there were very few off the shelf call center products available on the market. We partnered with Twilio to leverage their API to streamline the connection between our IVR and UI. I began by conducting interviews and shadowing sessions with agents and managers to understand their daily routines, pain points, and expectations for the new dashboard. This direct observation helped me gain empathy for the agents’ needs, uncovering key insights, such as the importance of real-time data visibility and the ability to access critical metrics without navigating away from the main screen.
                </p>
                <h5>Personnel factors – Stakeholders vs. users.</h5>
                <p>This was a bit murky with upper management requesting specific goals and functionality that seemed at odds with the representatives. Quick proto-personas maintained focus on the user, and prevented deviating by the non-user stakeholders from the agreed upon design goals, and grounded our user flow. This allowed us to have a focused user to reference for all decisions made during the design process.</p>
                <div className="col-sm-10 offset-sm-1">
                  <p className='text-center'><img alt="Temp Title" src={'../../images/caseStudies/Persona_ Call_Center.png'}></img></p>
                </div>
                <h5>Environmental factors – What will affect the user?</h5>
                <p>The call center location was mixed in amongst  cubicles of sales teams and operations teams creating a distracting environment.  Team consolidation, upgraded headsets, and sound reducing cubicle walls were recommended in addition to designing a new UI. Based on the insights gathered, I worked to map out the key workflows and create a problem statement that captured the primary user needs: quick data access, minimized system navigation, and an intuitive layout. By prioritizing these needs, I was able to outline design goals that directly addressed the challenges agents faced. I focused on simplifying data presentation, ensuring that critical metrics and alerts were front and center, and developing a clean, uncluttered layout. I created low-fidelity wireframes to test different layout configurations. Using a human-centered approach, I tested each iteration with real agents, gathering feedback that informed subsequent revisions. For instance, we quickly discovered that agents valued color-coded priority alerts and a collapsible sidebar for extended data. These insights led to design modifications that made the interface more responsive to real-time needs while preventing information overload.</p>
                <h5>User Flow</h5>
                <p>With a basic direction, I created the foundation for the user flow and defined the steps a call representative would follow once they answered the call.</p>
                <div className="col-sm-10 offset-sm-1">
                  <p className='text-center'><img alt="Temp Title" src={'../../images/caseStudies/calls-flow-v2.png'}></img></p>
                </div>
              </div>
      },
      { title: "Design & Prototyping",
        text:<div>
                <div className='row align-items-center'>
                  <div className="col-sm-10 offset-sm-1">
                    <h5>Getting there fast:</h5>
                    <p>White board, and paper prototypes were utilized to get the basic design ideas out for discussion. The CTO, and I quickly “roughed out” the basic interactive flow, and user actions then looked for potential development roadblocks that could slow the project. From there we, put together some rough visuals for using testing and review.</p>
                  </div>
                </div>
                <div className='row align-items-start'>
                  <div className="col-sm-10 offset-sm-1">
                    <p className='text-center'><img alt="Temp Title" src={'../../images/caseStudies/call-center-ideation-1-1-1.png'}></img></p>
                  </div>
                  <div className="col-sm-10 offset-sm-1">
                    <p>A few quick sketches allowed me to discover a major omission that would not have been fleshed out until the development cycle started.</p>
                  </div>
                </div>
              </div>
      },
      { title: "Prototyping",
        text:<div>
                <div className='row align-items-center'>
                  <div className="col-sm-10 offset-sm-1">
                    <h5>UI Design:</h5>
                    <p>The first prototype was created with Angular, and Middleman to define page interactions, and provide a walk through for the call center representatives. Given the importance of clarity and functionality, I focused on a minimalistic UI with strong visual hierarchies to avoid overwhelming agents. Key performance metrics were displayed prominently, with color-coded alerts to direct attention where it was needed most. Icons and interactive elements were also carefully chosen to reinforce quick, intuitive actions.</p>
                  </div>
                </div>
                <div className='row align-items-center'>
                  <div className="col-sm-10 offset-sm-1">
                    <p className='text-center'><img alt="Temp Title" src={'../../images/caseStudies/call-center-prototyping-1.png'}></img></p>
                  </div>
                </div>
                <div className='row align-items-center'>
                  <div className="col-sm-10 offset-sm-1">
                    <h5>Letting the developers do their work:</h5>
                    <p>This was the planned jump-off point for additional iterations, feature introduction, and provided a basic structure for the development team during production development.</p>
                    <h5>Test and Iterate</h5>
                    <p>After development, I led rounds of usability testing with agents to validate the design’s usability and responsiveness, which was later turned into sprint tickets as the project continued to evolve. Continuous feedback loops allowed us to refine elements like the alert system, collapsible sections, and layout spacing. These iterative improvements ensured that the dashboard provided a seamless experience, meeting both the business’s efficiency goals and the agents’ daily operational needs.</p>
                  </div>
                </div>
                <div className='row align-items-center'>
                  <div className="col-sm-10 offset-sm-1">
                    <p className='text-center'><img alt="Temp Title" src={'../../images/caseStudies/call-center-prototyping-2.png'}></img></p>
                  </div>
                </div>
              </div>
      },
      { title: "Closing the Loop",
        text: <div className='row align-items-center'>
                <div className="col-sm-10 offset-sm-1">
                  <h5>Results:</h5>
                  <p>The new call center dashboard delivered substantial improvements in both agent productivity and customer service efficiency. Through thorough planning and an HCD approach, we created a dashboard that streamlined workflows, reduced response times, and provided actionable data in a format that agents found intuitive and easy to navigate.</p>
                  <ul>
                    <li><b>Increased Efficiency and Reduced Response Time:</b> The centralized and simplified dashboard interface contributed to a 40% reduction in response times, allowing agents to access information faster and make quicker decisions. The streamlined workflows minimize distractions and ensure agents could focus on key metrics without unnecessary navigation.</li>
                    <li><b>Improved Data Accuracy and Insight Accessibility:</b> With essential metrics now visible on a single screen, agents reported a 30% increase in accuracy when handling customer interactions, reducing errors associated with navigating between systems. This improvement allowed agents to confidently address customer inquiries with the data they needed at their fingertips.</li>
                    <li><b>Enhanced Agent Satisfaction and Usability Ratings:</b> Post-launch feedback indicated a significant boost in agent satisfaction, with usability ratings improving by over 25%. Agents praised the clarity of the layout, the effectiveness of priority alerts, and the ease of accessing data, which contributed to a smoother, more satisfying work experience.</li>
                  </ul>
                  <h5>Was this successful?</h5>
                  <ul>
                    <li>First and foremost we got a production version up, and running in 3 months!</li>
                    <li>There was a 15% increase in monetized calls, specifically by representatives referencing the callback queue, and returning those dropped or missed calls.</li>
                    <li>The current iteration of this project supports a call team of 25 representatives in 3 different states using a variety of devices from Chromebooks to desktop computers.</li>
                  </ul>
                  <h5>What did I learn?</h5>
                  <ul>
                  <li>Call representatives aren’t machines!</li>
                  <li>Call scripts are great as prompts…not so great for reading verbatim.</li>
                  <li>Time zones needed to be considered more effectively.</li>
                  <li>Don’t give a dev team a straw man during the development cycle.</li>
                  <li>Visual design was minimized to get a fully functional production version. This led to some “clunky” interactions between screens, and a “spreadsheet” look design.</li>
                  <li>Unfortunately due to timing, the finalized visuals never made it into the final production version.</li>
                  </ul>
                </div>
              </div>
      }
    ]
    return (
      <section>
        <article className="container">
          <div className="row">
            <CaseStudyHeader
              CsTitle = "Project: Call Center Dashboard"
              CsSubtitle = "Real time, call center dashboard connecting live callers to eLocal advertising partners."
              CsHero = {'../../images/caseStudies/Calls-Center-Hero.png'}
            />
            <CaseStudySection content={content} />
            <CaseStudies />
          </div>
        </article>
      </section>
    )
  }
}

export default CallCenterDashboard



