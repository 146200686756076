import React from 'react'
import styled from '@emotion/styled'

import Card from './Card'

const CaseStudyHeader= styled.div`
  text-align: left;
  margin-top: 3rem;
  margin-bottom: .25rem;
  h5 {
    padding:0;
  }
  hr {
    margin-top .25rem;
  }
`

class CaseStudies extends React.Component {
  render() {
    const contentSection1 = [
      { Link: '/case-studies/dave-busters-ux',
        Image: "../../images/caseStudies/DB-Hero.png",
        Title: 'Dave & Busters',
        ImgClass: 'smallImage',
        Content: 'Omni channel UX project and design system development',
        Color:'#dbdae9',
        Size: '140%'
      },
      { Link: '/case-studies/coca-cola-and',
        Image: '../../images/caseStudies/CokeAnd-Hero.png',
        Title:'Coca-Cola&',
        Content:'Unique mixology site designed for Coca-Cola',
        Color:'#d4d4d4',
        Size: '90%'
      }
    ]
    const contentSection2 = [
      { Link: '/case-studies/call-center-dashboard',
        Image: "../../images/caseStudies/Calls-Center-Hero.png",
        Title: 'Call Center Dashboard',
        ImgClass: 'smallImage',
        Content: 'Real time, call center dashboard connecting live callers to eLocal advertising partners.',
        Size: '140%'
      },
      { Link: '/case-studies/calls-leads-feature',
        Image: '../../images/caseStudies/BU-Calls-Hero.png',
        Title:'Calls & Leads Feature',
        Content:'Fully integrate business user dashboard with calls & leads acceptance functionality.',
        Color:'#e6f8e5',
        Size: '140%'
      },
      { Link: '/case-studies/additional-projects',
        Image: '../../images/caseStudies/Campaigns-Hero.png',
        Title: 'Additional Projects',
        Content: 'A collection of assorted projects.',
        Color: '#dbdae9',
        Size: '140%'
      }
    ]

    return(
      <section>
        <div className="container">
          <div className="row">
            <CaseStudyHeader className="col">
              <h5>Case Studies</h5>
              <hr className="hr-theme hidden-xs"/>
            </CaseStudyHeader>
          </div>
          <div className="row">
            <Card content={contentSection1} />
          </div>
          <div className="row">
            <Card content={contentSection2} />
          </div>
        </div>
      </section>
    )
  }
}

export default CaseStudies