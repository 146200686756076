import React from 'react'

import Header from '../Components/Header'
import SectionTitleContainer from "../Components/Section/SectionTitleContainer"
import CaseStudies from '../Components/CaseStudy/CaseStudies'

class Experience extends React.Component {
  render() {
    return(
      <div>
        <Header
        title="Michael McMillan,"
        jobTitle="UX Leader"
        subtitle="People-focused UX leader and strategist, producing elegant designs to meet business objectives, and create thoughtful experiences and solutions."
        />
        <section>
          <div className="container">
          <SectionTitleContainer
                title="Overview"
                content={[
                  <div key="4">
                    <ul className="header-list" key="header-list-1">
                      <li><b>Human-Centered Design:</b> Expert in translating complex business strategies into clear, engaging, and user-centered digital experiences.</li>
                      <li><b>Team Development:</b> Proven success in developing and mentoring teams of designers, ensuring high performance and growth through effective training and feedback.</li>
                      <li><b>Cross-Functional Collaboration:</b> Extensive experience working with product, strategy, and engineering teams to establish design priorities, ensuring alignment with business objectives.</li>
                      <li><b>Design System Evolution:</b> Skilled in building and evolving design systems that maintain consistency, scalability, and quality across products and digital ecosystems.</li>
                    </ul>
                  </div>
                ]}
            />
            <SectionTitleContainer
              title="Experience"
              content={[
              <div key="1">
                <div className="experience-company">
                  <h4>West Cary Group</h4>
                  <div className="experience-group">
                    <h5 className="experience-title">Senior Creative Director, UX/UI</h5>
                    <p className="experience-date">July 2023 - Present</p>
                    <p className="experience-description">As Senior Creative Director at West Cary Group, I lead a talented team of product designers, translating complex business strategies into user-centered digital experiences for high-profile clients, including Fortune 500 financial companies. I collaborate closely with cross-functional teams in product, strategy, and engineering to ensure seamless integration of UX processes into Agile development cycles, driving consistent results and aligning with business objectives.</p>
                    <ul className="about-ordered-list">
                      <li>Develop and execute tailored UX/UI strategies, boosting user engagement across digital platforms.</li>
                      <li>Lead product design for a major financial client, improving workflow efficiency by 20% through effective integration of design systems.</li>
                      <li>Provide ongoing UX support for Fortune 500 clients, enhancing customer engagement by 20%.</li>
                      <li>Champion a design excellence framework, reducing iteration time and ensuring delivery of high-quality digital experiences.</li>
                      <li>Represented the agency as a UX/UI thought leader increase brand awareness.</li>
                    </ul>
                  </div>
                </div>
                <div className="experience-company">
                  <h4>Ansira</h4>
                  <div className="experience-group">
                    <h5 className="experience-title">Senior Director, User Experience & Product Design</h5>
                    <p className="experience-date">May 2022 - July 2023</p>
                    <p className="experience-description">Managed a global team of 50+ designers, aligning product vision with design strategies and establishing global UX processes to enhance customer experiences across web, mobile, and tablet platforms. Collaborated closely with product, strategy, and engineering leadership to prioritize and deliver end-to-end digital solutions.</p>
                    <ul className="about-ordered-list">
                      <li>Increased product adoption by 15% through cross-functional collaboration, aligning UX efforts with business and customer needs.</li>
                      <li>Integrated UX processes into Agile sprints, boosting design efficiency by 20% across multiple regions.</li>
                      <li>Evolved and scaled the Ansira and Sincro design systems, ensuring consistency across product platforms.</li>
                      <li>Led client pitch meetings, showcasing design capabilities and delivering strategic assets such as roadmaps, competitive analyses, research insights, personas, and responsive wireframes for web and mobile projects.</li>
                      <li>Partnered with strategy, product, and engineering teams to create cohesive design outcomes and drive product adoption.</li>
                      <li>Successfully launched a digital project for a major entertainment client, resulting in a 25% revenue lift.</li>
                    </ul>
                  </div>
                </div>
                <div className="experience-company">
                  <h4>Sincro, An Ansira Company</h4>
                  <div className="experience-group">
                    <h5 className="experience-title">Sr. Director, User Experience Design, Product Design & Client Onboarding</h5>
                    <p className="experience-date">August 2021 - May 2022</p>
                    <p className="experience-description">Experienced design leader focused on building cohesive stakeholder teams to drive product growth, optimize output, and maintain a holistic view from initial client engagement through final product delivery. Led and mentored a team of 50+ designers and consultants across four teams: Experience Design, Product/UX Design, Visual Design, and Onboarding Consultants.</p>
                      <ul className="about-ordered-list">
                      <li>Developed and implemented UX processes, design systems, and infrastructure, integrating Agile development sprints to align business direction and support product managers and development teams.</li>
                      <li>Reduced process steps from 50 to 30, improving delivery timelines by 15% through a new cross-functional program team.</li>
                      <li>Delivered client pitch work and key deliverables, including roadmaps, competitive analyses, research insights, personas, wireframes, and future-state assessments for web, mobile, and tablet projects.</li>
                      <li>Increased user satisfaction by 20% through comprehensive UX research initiatives and data-driven insights.</li>
                      <li>Managed client onboarding and activation, focusing on the client life cycle, retention, and customer service to ensure a seamless and high-quality customer experience.</li>
                    </ul>
                  </div>
                  <div className="experience-group">
                    <h5 className="experience-title">Director, User Experience Design, Product Design & Client Onboarding</h5>
                    <p className="experience-date">August 2021 - May 2022</p>
                    <p className="experience-description">As Director, I led a team of 25+ designers and consultants, focusing on delivering user-centered design solutions for a diverse client base of over a portfolio of diverse accounts. I facilitated discovery sessions and design sprints to drive product innovation and market growth.</p>
                      <ul className="about-ordered-list">
                      <li>Increased client satisfaction and product adoption by 20% through the implementation of human-centered design strategies.</li>
                      <li>Achieved a 10% increase in market share for MVP products through targeted design roadmaps and sprint planning.</li>
                      <li>Implemented a scalable pre-sales support program, securing 10 new business contracts and boosting annual revenue by 12%.</li>
                      <li>Reduced go-to-market cycle time by 30 days through process improvement programs, leading to a 5% revenue increase and launched 100 clients in 70 days with 100% client satisfaction.</li>
                    </ul>
                  </div>
                  <div className="experience-group">
                    <h5 className="experience-title">Creative Director, Design Team Manager</h5>
                    <p className="experience-date">August 2020 - August 2021</p>
                    <p className="experience-description">Led 10+ design teams in digital marketing, specializing in targeted ads and user-centered automotive website design, resulting in a 20% increase in user engagement and a 15% boost in conversion rates.</p>
                    <ul className="about-ordered-list">
                      <li>Drove the design of the Sincro brand and digital experience, inclusive of all brand identity assets including the most recent Sincro and Ansira websites.</li>
                      <li>Led the development of a centralized product design system library for use by multiple internal teams to streamline all product resources.</li>
                      <li>Created a pre-sales design support program that is scalable and consistent to target and win new businesses.</li>
                      <li>Successfully shifted internal organization mentality from selling features to helping clients tell their stories and share their experiences.</li>
                      <li>Led 10+ design teams in digital marketing, specializing in targeted ads and user-centered automotive website design, resulting in a 20% increase in user engagement and a 15% boost in conversion rates.</li>
                      <li>Drove the design of the Sincro brand and digital experience, inclusive of all brand identity assets including the most recent Sincro and Ansira websites. • Led the development of a centralized product design system library for use by multiple internal teams to streamline all product resources. • Created a pre-sales design support program that is scalable and consistent to target and win new businesses. • Successfully shifted internal organization mentality from selling features to helping clients tell their stories and share their experiences.</li>
                    </ul>
                  </div>
                </div>
                <div className="experience-company">
                  <h4>CDK Global</h4>
                  <div className="experience-group">
                    <h5 className="experience-title">Creative Director, Design Team Manager</h5>
                    <p className="experience-date">June 2019 - August 2020</p>
                    <p className='experience-description'>At CDK Global, I led 10+ design teams in creating innovative, customer-centric digital marketing solutions. My work focused on optimizing design quality, project delivery timelines, and cross-functional collaboration to achieve measurable business results.</p>
                    <ul className="about-ordered-list">
                      <li>Developed capacity models and KPIs, optimizing team efficiency by 10% and enhancing team value to the business.</li>
                      <li>Increased design excellence by promoting a user-first approach, leading to an improvement in customer satisfaction scores.</li>
                      <li>Improved QA efficiency by 30% through a cross-training program between US and India teams, enhancing collaboration and reducing project delays.</li>
                    </ul>
                  </div>
                </div>
                <div className="experience-company">
                  <h4>eLocal.com</h4>
                  <div className="experience-group">
                    <h5 className="experience-title">User Experience Consultant</h5>
                    <p className="experience-date">November 2017 - June 2019</p>
                    <p>After a private equity purchase, I showed my adaptability by changing my role from a full-time employee to a consultant. This change allowed me to focus on the product design aspect of the internal product offerings.<br/></p>
                  </div>
                  <div className="experience-group">
                    <h5 className="experience-title">Creative Director & UX Director</h5>
                    <p className="experience-date">September 2010 - November 2017</p>
                    <p className="experience-description">As the Creative and UX Director at eLocal.com, I led the UX strategy and design for B2B and B2C applications, delivering scalable digital solutions that drove business growth and customer engagement. I managed the end-to-end design process, from research and prototyping to implementation and testing. Transformed and grew the design presence for a 10-year-old performance-based advertising company whose mission is to connect consumers with local businesses.</p>
                    <ul className="about-ordered-list">
                      <li>Increased user engagement and conversion rates through strategic UX design for responsive web and mobile applications.</li>
                      <li>Conducted user research and usability testing, leading to an improvement in product-market fit and an increase in lead conversions.</li>
                      <li>Built and implemented a comprehensive UI style guide and pattern library, reducing iteration time by 15% and enhancing design consistency.</li>
                      <li>Launched a continuous improvement program, streamlining development processes and reducing team development time by 5%.</li>
                    </ul>
                  </div>
                </div>
                <div className="experience-company">
                  <h4>Trend Source, INC</h4>
                  <div className="experience-group">
                    <p>Primary objective to maintain overall client vision and objectives thru strong design concepts and leveraging my relationship building skills.</p>
                    <h5 className="experience-title">Principal, Experience Design</h5>
                    <p className="experience-date">October 2007 – May 2019</p>
                    <p>After a private equity purchase, I changed my role from a full-time employee to a consultant’s role. This change allowed me to solely focus on the product design aspect of the internal product offerings as an IC.<br/></p>
                    <ul className="about-ordered-list">
                      <li>Visual, UX, and UI front-end developer for One Accord Digital (Transform), completed a large project for Kodak on-time and on budget which secured One Accord Digital as agency of choice for 2015.</li>
                      <li>Led the UX research, design and UI development of Dream Dinners', Loving with Food e-commerce website for the launch of their first direct to consumer food product.</li>
                      <li>Conducted stakeholder and user interviews, led test plan development, formulated and analyzed survey results, and implemented usability testing for prototype for client projects.</li>
                    </ul>
                  </div>
                </div>
              </div>
              ]}
            />
          </div>
          <CaseStudies />
        </section>
      </div>
    )
  }
}

export default Experience